export interface Props {
  color: string;
  hoverColor: string;
}

const Facebook = ({ color, hoverColor }: Props) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="36"
        className={`text-${color} fill-current hover:text-${hoverColor}`}
        viewBox="0 0 40 41"
      >
        <path
          fillRule="evenodd"
          d="M25 1.693a9.923 9.923 0 00-7.071 2.975A10.238 10.238 0 0015 11.85v3.385h-3.333c-.92 0-1.667.758-1.667 1.693v6.77c0 .936.746 1.694 1.667 1.694H15V37.24c0 .935.746 1.693 1.667 1.693h6.666c.92 0 1.667-.758 1.667-1.693v-11.85h3.333c.765 0 1.432-.528 1.617-1.282l1.667-6.77a1.714 1.714 0 00-.303-1.453c-.316-.41-.8-.651-1.314-.651h-5V11.85h5c.92 0 1.667-.758 1.667-1.693V3.386c0-.935-.747-1.693-1.667-1.693h-5zm-4.714 5.369A6.615 6.615 0 0125 5.078h3.333v3.386H25c-.884 0-1.732.357-2.357.992a3.413 3.413 0 00-.976 2.394v5.078c0 .935.746 1.692 1.666 1.692h4.532l-.833 3.386h-3.699c-.92 0-1.666.758-1.666 1.693v11.85h-3.334v-11.85c0-.935-.746-1.693-1.666-1.693h-3.334V18.62h3.334c.92 0 1.666-.757 1.666-1.692v-5.079c0-1.795.703-3.518 1.953-4.787z"
          clipRule="evenodd"
        ></path>
      </svg>
    </div>
  );
};

export default Facebook;
