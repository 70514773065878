export const galleryImages: string[] = [
  "/images/cultivators/gallery/DSC01566.JPG",
  "/images/cultivators/gallery/DSC01490.JPG",
  "/images/cultivators/gallery/DSC01512.JPG",
  "/images/cultivators/gallery/DSC01527.JPG",
  "/images/cultivators/gallery/DSC01529.JPG",
  "/images/cultivators/gallery/DSC01545.JPG",
  "/images/cultivators/gallery/DSC01573.JPG",
  "/images/cultivators/gallery/DSC01612.JPG",
  "/images/cultivators/gallery/DSC01667.JPG",
  "/images/cultivators/gallery/DSC01700.JPG",
  "/images/cultivators/gallery/DSC01719.JPG",
  "/images/cultivators/gallery/DSC01745.JPG",
  "/images/cultivators/gallery/DSC01771.JPG",
  "/images/cultivators/gallery/DSC01777.JPG",
  "/images/cultivators/gallery/DSC01788.JPG",
  "/images/cultivators/gallery/DSC01469.JPG",
];
