import { FormEvent, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-responsive-modal";
import { useNavigate } from "react-router";
import { RootState } from "../redux/configureStore";
import { closeForm } from "../redux/features/cultivatorsSignUpFormSlice";

type FormState = {
  name: string;
  jobTitle: string;
  phone: string;
  email: string;
  company: string;
};

type FormStateError = {
  name: boolean;
  jobTitle: boolean;
  phone: boolean;
  email: boolean;
  company: boolean;
};

const JoinOurNetwork = () => {
  const navigate = useNavigate();
  const [formState, setFormState] = useState<FormState>({
    name: "",
    phone: "",
    jobTitle: "",
    email: "",
    company: "",
  });

  const [formErrors, setFormErrors] = useState<FormStateError>({
    email: false,
    name: false,
    company: false,
    jobTitle: false,
    phone: false,
  });
  const handleChange = (key: string, newValue: string) => {
    setFormState({ ...formState, [key]: newValue });
  };
  const exit = () => {
    closeModal();
    setFormState({
      email: "",
      name: "",
      jobTitle: "",
      phone: "",
      company: "",
    });
  };

  const addAssignee = async () => {
    let response = await fetch(
      `https://event-manager.bb.cultiv.llc/api/event/19/external-assign?name=${formState.name}&phone=${formState.phone}&email=${formState.email}&company_name=${formState.company}&job_title=${formState.jobTitle}`,
      {
        method: "POST",
      }
    );
    let { data } = await response.json();
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const emptyInputs = Object.entries(formState).filter(([key, val]) => !val);
    console.log(emptyInputs);
    if (emptyInputs.length <= 0) {
      await addAssignee();
      toast.success("You are now a Cultivator!", {
        duration: 1500,
      });
      exit();
      navigate(`/cultivators-forum/P-II`);
    } else {
      setFormErrors(() => {
        return emptyInputs.reduce(
          (acc, [key]) => ({
            ...acc,
            [key]: true,
          }),
          {}
        ) as FormStateError;
      });
    }
  };

  useEffect(() => {
    // clear errors on user input
    setFormErrors({
      email: false,
      name: false,
      jobTitle: false,
      company: false,
      phone: false,
    });
  }, [formState]);

  const modalState = useSelector((state: RootState) => state.cultivatorSignUpForm.cultivatorsOpen);
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(closeForm());
  };

  return (
    <Modal
      open={modalState}
      onClose={exit}
      classNames={{
        root: "sign-up-modal",
        overlay: "sign-up-modal",
        modal: "sign-up-modal",
        modalContainer: "sign-up-modal",
      }}
      center
    >
      <div className="sm:p-2 w-screen sm:max-w-md font-serif font-semibold m-auto max-w-xs overflow-y-hidden">
        <form className="w-full" onSubmit={handleSubmit}>
          <div className="flex flex-col gap-8 h-full">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2 w-full pr-6 sm:pr-0 ">
                <label htmlFor="name">Name:</label>
                <input
                  type="name"
                  name="name"
                  id="login-name"
                  placeholder="John Doe"
                  value={formState.name}
                  className={`${
                    formErrors.name ? "border-red-600" : "border-gray-400"
                  } border-2 rounded-lg p-2 italic`}
                  onChange={(e) => {
                    handleChange("name", e.target.value);
                  }}
                />
              </div>
              <div className="flex flex-col gap-2 w-full pr-6 sm:pr-0 ">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  name="email"
                  id="login-email"
                  placeholder="example@email.com"
                  value={formState.email}
                  className={`${
                    formErrors.email ? "border-red-600" : "border-gray-400"
                  } border-2 rounded-lg p-2 italic`}
                  onChange={(e) => {
                    handleChange("email", e.target.value);
                  }}
                />
              </div>
              <div className="flex flex-col gap-2 w-full pr-6 sm:pr-0 ">
                <label htmlFor="Number">Number:</label>
                <input
                  type="string"
                  name="Phone number"
                  id="login-number"
                  placeholder="+1(123)456-7890"
                  value={formState.phone}
                  className={`${
                    formErrors.phone ? "border-red-600" : "border-gray-400"
                  } border-2 rounded-lg p-2 italic`}
                  onChange={(e) => {
                    handleChange("phone", e.target.value);
                  }}
                />
              </div>
              <div className="flex flex-col gap-2 pr-6 sm:pr-0 ">
                <label htmlFor="job">Company:</label>
                <input
                  type="string"
                  name="company"
                  id="company-name"
                  placeholder="Current Company"
                  value={formState.company}
                  className={`${
                    formErrors.company ? "border-red-600" : "border-gray-400"
                  } border-2 rounded-lg p-2 italic`}
                  onChange={(e) => {
                    handleChange("company", e.target.value);
                  }}
                />
              </div>
              <div className="flex flex-col gap-2 pr-6 sm:pr-0 ">
                <label htmlFor="job">Job Title:</label>
                <input
                  type="string"
                  name="job"
                  id="job-title"
                  placeholder="Current Job Title"
                  value={formState.jobTitle}
                  className={`${
                    formErrors.jobTitle ? "border-red-600" : "border-gray-400"
                  } border-2 rounded-lg p-2 italic`}
                  onChange={(e) => {
                    handleChange("jobTitle", e.target.value);
                  }}
                />
              </div>
              <button
                type="submit"
                className="bg-gray-800 text-gray-50 font-sans rounded-md p-2 lg:px-6 min-w-fit w-32 self-center"
              >
                Sign Up
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default JoinOurNetwork;
