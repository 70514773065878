import { Article } from "../assets/cultivators/aboutCultivators";
import MarkDown from "./MarkDown";

const AboutCultivators = () => {
  return (
    <div className="flex flex-col w-full gap-16">
      <MarkDown
        proseHeaders="prose-headings:m-0"
        proseLead="prose-lead:leading-none"
        proseList="prose-ul:list-disc"
        prosePadding="pr-8"
        articleBody={Article}
      ></MarkDown>
    </div>
  );
};

export default AboutCultivators;
