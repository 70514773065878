import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-responsive-modal";
import { galleryImages } from "../assets/cultivators/images";
import { RootState } from "../redux/configureStore";
import { close, next, prev } from "../redux/features/cultivatorsImageModalSlice";

const CultivatorsImageModal = () => {
  const { imgSrc, openImageModal } = useSelector((state: RootState) => state.cultivatorsImageModal);
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(close());
  };
  const inc = () => {
    dispatch(next(galleryImages.length));
  };
  const dec = () => {
    dispatch(prev());
  };

  return (
    <Modal
      center
      open={openImageModal}
      onClose={closeModal}
      classNames={{
        modal: "cultivators-modal",
        modalContainer: "cultivators-modal-container",
        closeIcon: "cultivators-close-icon",
      }}
    >
      <button
        onClick={inc}
        className="flex justify-end absolute -right-3 z-50 top-1/2 invert w-10 h-10 "
      >
        <ChevronRightIcon className="w-10 h-10" />
      </button>
      <button
        onClick={dec}
        className="flex justify-end absolute -left-2.5 z-50 top-1/2 invert w-10 h-10 "
      >
        <ChevronLeftIcon className="w-10 h-10" />
      </button>

      <img src={galleryImages[Number(imgSrc)]} alt={`Cultivators Forum Panel 1 gallery Image `} />
    </Modal>
  );
};

export default CultivatorsImageModal;
