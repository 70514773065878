import { Remark } from "react-remark";
import rehypeAutoLinkHeadings from "rehype-autolink-headings";
import rehypeSlug from "rehype-slug";
import remarkGemoji from "remark-gemoji";

export interface Props {
  articleBody: string;
  proseLead?: string;
  proseHeaders?: string;
  proseList?: string;
  proseParagraph?: string;
  proseImg?: string;
  proseWidth?: string;
  prosePadding?: string;
}

const MarkDown = ({
  articleBody,
  proseLead,
  proseHeaders,
  proseList,
  proseParagraph,
  proseImg,
  proseWidth,
  prosePadding,
}: Props) => {
  return (
    <article
      className={`md:prose-xl prose-sm flex flex-col justify-start items-start ${
        proseImg ? proseImg : "prose-img:float-right prose-img:w-1/3"
      } prose-img:aspect-auto prose-img:object-fit ${
        proseLead ? proseLead : "prose-lead:leading-6"
      } ${proseHeaders} ${proseList} ${proseParagraph} ${proseWidth} ${prosePadding}`}
    >
      <Remark
        remarkPlugins={[remarkGemoji as any]}
        remarkToRehypeOptions={{ allowDangerousHtml: true }}
        rehypePlugins={[rehypeSlug as any, rehypeAutoLinkHeadings]}
      >
        {articleBody}
      </Remark>
    </article>
  );
};

export default MarkDown;
