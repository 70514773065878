/**
 * Returns the first and second subdirectory
 */

import { useLocation } from "react-router";

export interface PathDetails {
  page: string;
  subDirectory?: string;
}

const usePathname = (): PathDetails => {
  const { pathname } = useLocation();
  const path = pathname.split("/");
  const pathDetails: PathDetails = {
    page: path[1],
    subDirectory: path[2],
  };

  return pathDetails;
};

export default usePathname;
