import { GlobeAltIcon } from "@heroicons/react/20/solid";

export interface Props {
  link: string;
  text: string;
}

const WebsiteLinkButton = ({ link, text }: Props) => {
  return (
    <a
      className="px-4 py-0.5 text-xs sm:text-xl max-w-fit bg-cultiv-dark-blue text-gray-50 hover:bg-cultiv-yellow hover:text-gray-900"
      href={`${link.includes("http") ? link : `https://${link}`}`}
      target="_blank"
    >
      <div className="flex w-36 sm:w-60 h-6 sm:h-11 justify-between items-center gap-2 sm:gap-4">
        <p>{text}</p>
        <GlobeAltIcon className="sm:w-6 w-4 sm:h-6 h-4" />
      </div>
    </a>
  );
};

export default WebsiteLinkButton;
