import { CultivContact } from "../schema";

const ContactDetails = ({ contact }: CultivContact) => {
  return (
    <p className="font-normal text-center text-sm sm:text-base md:text-xl xl:text-2xl">
      {contact}
    </p>
  );
};

export default ContactDetails;
