import { Transition } from "@headlessui/react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useTextTruncation from "../hooks/useTextTruncation";

export interface Props {
  title: string;
  imgSrc: string;
  desc: string;
  slug: string;
  cycles: number;
}

export default function ProgramCard({ title, imgSrc, desc, slug, cycles }: Props) {
  const [isInside, setIsInside] = useState<boolean>(false);
  const truncatedText = useTextTruncation(desc, 150);
  const navigate = useNavigate();
  return (
    <div
      className={`border-[1px] border-gray-100 h-full w-full aspect-square relative flex items-end justify-end`}
      onMouseEnter={() => {
        setIsInside(true);
      }}
      onMouseLeave={() => {
        setIsInside(false);
      }}
    >
      <img src={imgSrc} alt={title} className="absolute top-0 left-0 w-full h-full -z-10" />

      <Transition
        show={isInside}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={`h-full w-full`}
      >
        <div
          onClick={() => {
            navigate(`/cultivation/program/${slug}`);
          }}
          className="bg-cultiv-sky text-gray-900 flex flex-col gap-3 justify-between items-start p-4 h-full w-full cursor-pointer"
        >
          <div className="flex flex-col justify-start items-start gap-1">
            <h1 className="2xl:leading-8 font-bold 2xl:text-lg  ">{title}</h1>
          </div>
          <p className=" 2xl:text-base text-sm 2xl:leading-8 md:text-start">{truncatedText}</p>
          <Link
            to={`/cultivation/program/${slug}`}
            className="px-3 lg:py-1 xl:py-[1px] 2xl:py-1 flex justify-center items-center lg:w-[180px] xl:w-[140px] 2xl:w-[180px] border-[3px] border-gray-950 hover:bg-gray-950 hover:text-gray-50 border-solid 2xl:text-xl"
          >
            <p className="text-sm">Discover more</p>
          </Link>
        </div>
      </Transition>
    </div>
  );
}
