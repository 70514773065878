import { Transition } from "@headlessui/react";
import { useState } from "react";
import { Link } from "react-router-dom";
import usePathname from "../hooks/usePathname";
import useTextTruncation from "../hooks/useTextTruncation";
import { Company } from "../schema";
import Image from "./Image";

export interface Props {
  company: Company;
}

export default function CompanyPreviewCard({ company }: Props) {
  const [isInside, setIsInside] = useState<boolean>(false);
  const { page } = usePathname();
  const truncatedText = useTextTruncation(company.description_summary, 250);

  return (
    <div
      className={`border-[1px] border-gray-100  w-full aspect-square relative flex items-end justify-end`}
      onMouseEnter={() => {
        setIsInside(true);
      }}
      onMouseLeave={() => {
        setIsInside(false);
      }}
    >
      {company.profile_picture ? (
        <Image
          src={company.profile_picture}
          alt={company.name}
          className="absolute top-0 left-0 w-full h-full -z-10 object-contain"
        />
      ) : (
        <div
          className={`absolute top-0 left-0 w-full h-full flex justify-center items-center bg-cultiv-sky -z-10`}
        >
          <h1 className="text-white font-black 2xl:text-5xl xl:text-4xl md:text-3xl text-base w-full text-center">
            {company.name}
          </h1>
        </div>
      )}

      <Transition
        show={isInside}
        enter="transition-opacity ease-in duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-out duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={`h-full w-full`}
      >
        <Link to={`/organizations/${company.name_slug}`} className="w-full h-full">
          <div
            className={`${
              page === "collective" ? "bg-cultiv-yellow" : "bg-cultiv-sky"
            } text-gray-900 flex flex-col gap-1  justify-start items-start p-4 h-full w-full`}
          >
            <div className="flex flex-col justify-start items-start gap-1">
              <h1 className="font-black md:text-xl">{company.name}</h1>
            </div>
            <div className="flex justify-center items-center flex-1">
              <p className="3xl:text-2xl xl:text-base  text-lg">{truncatedText}</p>
            </div>
          </div>
        </Link>
      </Transition>
    </div>
  );
}
