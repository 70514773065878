import { createSlice } from "@reduxjs/toolkit";

export interface CultivatorsSignUpFormSlice {
  cultivatorsOpen: boolean;
}

const initialState: CultivatorsSignUpFormSlice = {
  cultivatorsOpen: false,
};

export const cultivatorsSignUpFormSlice = createSlice({
  name: "CultivatorsSignUp",
  initialState,
  reducers: {
    openForm: (state) => {
      document.querySelector("html")?.classList.add("overlay");
      state.cultivatorsOpen = true;
    },
    closeForm: (state) => {
      document.querySelector("html")?.classList.remove("overlay");
      state.cultivatorsOpen = false;
    },
    reverse: (state) => {
      state.cultivatorsOpen = !state.cultivatorsOpen;
    },
  },
});

export const { openForm, closeForm, reverse } = cultivatorsSignUpFormSlice.actions;
export default cultivatorsSignUpFormSlice.reducer;
