import { useDispatch } from "react-redux";
import { openForm } from "../redux/features/cultivatorsSignUpFormSlice";
import usePathname from "../hooks/usePathname";
import { useEffect } from "react";

export default function JoinCultivators() {
  const dispatch = useDispatch();
  const openModal = () => {
    dispatch(openForm());
  };
  const { page } = usePathname();
  useEffect(() => {
    if (page === "join") openModal();
  }, []);
  return (
    <div
      className={`flex flex-col justify-center gap-2 relative w-full h-full border-[1px] border-solid border-black`}
    >
      <div className="relative  w-full flex justify-center items-center  overflow-hidden">
        <button onClick={openModal} className="w-full h-full">
          <img
            src="/images/becomeACultivator.svg"
            alt="Join the cultivators"
            className="w-full h-full transition-all transform ease-in-out duration-[800ms] hover:scale-110"
          />
        </button>
      </div>
      <div className="flex justify-center items-center">
        <button onClick={openModal}>
          <h2 className="text-gray-900 2xl:text-2xl xl:text-lg md:text-sm text-xs">
            Become A Cultivator
          </h2>
        </button>
      </div>
    </div>
  );
}
