import { useDispatch } from "react-redux";
import { galleryImages } from "../assets/cultivators/images";
import { open } from "../redux/features/cultivatorsImageModalSlice";
import CultivatorsImageModal from "./CultivatorsImageModal";
import Image from "./Image";

const Gallery = () => {
  const dispatch = useDispatch();
  const openModal = (img: string) => {
    dispatch(open(img));
  };

  return (
    <>
      <div
        className={`grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-y-8 w-full place-items-center gap-x-8 pr-6`}
      >
        {galleryImages.map((image, index) => {
          return (
            <button
              key={index}
              onClick={() => {
                openModal(index.toString() || "");
              }}
              className="cursor-pointer w-full h-full"
            >
              <Image src={image} alt={`Gallery Image ${index}`} />
            </button>
          );
        })}
      </div>
      <CultivatorsImageModal />
    </>
  );
};

export default Gallery;
