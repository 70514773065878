export interface Props {
  color: string;
  hoverColor: string;
  width?: string;
  height?: string;
}

const Linkedin = ({ color, hoverColor, width, height }: Props) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={36}
        height={36}
        fill="none"
        className={`text-${color} fill-current hover:text-${hoverColor} ${width && width} ${
          height && height
        }`}
      >
        <path d="M26.25 13.498a8.129 8.129 0 0 0-3.848.968A1.5 1.5 0 0 0 21 13.498h-6a1.5 1.5 0 0 0-1.5 1.5v18a1.5 1.5 0 0 0 1.5 1.5h6a1.5 1.5 0 0 0 1.5-1.5v-8.25a1.5 1.5 0 1 1 3 0v8.25a1.5 1.5 0 0 0 1.5 1.5h6a1.5 1.5 0 0 0 1.5-1.5v-11.25a8.26 8.26 0 0 0-8.25-8.25Zm5.25 18h-3v-6.75a4.5 4.5 0 0 0-9 0v6.75h-3v-15h3v1.055a1.5 1.5 0 0 0 2.672.937 5.225 5.225 0 0 1 9.328 3.26v9.748Zm-21-18h-6a1.5 1.5 0 0 0-1.5 1.5v18a1.5 1.5 0 0 0 1.5 1.5h6a1.5 1.5 0 0 0 1.5-1.5v-18a1.5 1.5 0 0 0-1.5-1.5Zm-1.5 18H6v-15h3v15ZM7.522 2.313a4.85 4.85 0 1 0-.085 9.685h.042a4.848 4.848 0 0 0 1.975-9.384 4.85 4.85 0 0 0-1.932-.301ZM7.48 8.998h-.042a1.833 1.833 0 0 1-2.055-1.842c0-1.12.84-1.843 2.14-1.843a1.851 1.851 0 0 1 2.093 1.843c0 1.12-.839 1.842-2.136 1.842Z" />
      </svg>
    </div>
  );
};

export default Linkedin;
