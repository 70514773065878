import { Link } from "react-router-dom";
import { Person } from "../schema";
import Image from "./Image";

export interface Props {
  person: Person;
  panelist?: boolean;
}

export default function PersonCard({ person, panelist }: Props) {
  return (
    <div className={`flex flex-col gap-2 relative w-full h-full`}>
      <div className="relative w-full flex justify-center items-center border-2 border-transparent overflow-hidden  aspect-square ">
        {panelist ? (
          <img
            src={person.profile_picture ? person.profile_picture : "/images/2815428.png"}
            alt={person.name}
            className="w-full transition-all transform ease-in-out duration-[800ms] hover:scale-110 aspect-square object-cover"
          />
        ) : (
          <Link to={`/profile/${person.idea_name}`} className="w-full aspect-square">
            <Image
              src={person.profile_picture ? person.profile_picture : "/images/2815428.png"}
              alt={person.name}
              className="w-full transition-all transform ease-in-out duration-[800ms] hover:scale-110 aspect-square object-cover"
            />
          </Link>
        )}
      </div>
      <div className="flex flex-col gap-1 md:p-2 p-0">
        {panelist ? (
          <h2 className="text-gray-900 2xl:text-2xl md:text-lg ">{person.name}</h2>
        ) : (
          <Link to={`/profile/${person.idea_name}`}>
            <h2 className="text-gray-900 2xl:text-2xl md:text-lg ">{person.name}</h2>
          </Link>
        )}
        {person.job_title &&
          (panelist ? (
            <h3 className="text-gray-600 font-medium 2xl:text-lg text-sm">{person.job_title}</h3>
          ) : (
            <Link to={`/profile/${person.idea_name}`}>
              <h3 className="text-gray-600 font-medium 2xl:text-lg text-sm">{person.job_title}</h3>
            </Link>
          ))}
      </div>
    </div>
  );
}
