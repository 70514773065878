import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router";
import { cultivationDetails as bio } from "../assets/componentDetails";
import { cultivationTabs as tabs } from "../assets/pageTabs";
import CompaniesFeed from "../components/CompaniesFeed";
import GridController from "../components/GridController";
import Header from "../components/Header";
import ProgramFeed from "../components/ProgramFeed";
import usePageReset from "../hooks/usePageReset";

export default function Cultivation() {
  const { cultivationTab } = useParams();
  const [displayed, setDisplayed] = useState<string>((cultivationTab as string) || "programs");

  const navigate = useNavigate();

  const chooseTab = useCallback((tabId: string) => {
    setDisplayed(tabId);
  }, []);

  const pageReset = usePageReset();

  useEffect(() => {
    const tabFound = tabs.find((tab) => tab.id === cultivationTab);
    if (tabFound) setDisplayed(cultivationTab as string);
    if (!tabFound && cultivationTab) navigate(`/404`);
  }, [cultivationTab, setDisplayed]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="keywords" content="programs clients cultiv cultivation" />
        <meta name="description" content="Cultiv's role to cultivate the ecosystem" />
        <meta name="robots" content="index, follow" />
        <title>Cultivation</title>
      </Helmet>
      <div className="w-full flex flex-col justify-center items-center gap-14">
        <Header desc={bio.text} color="bg-cultiv-sky" />
        <div className="flex flex-col gap-4 justify-center items-center w-full px-6">
          <GridController
            displayed={displayed}
            setDisplayed={chooseTab}
            tabs={tabs}
            page="cultivation"
          />
          {displayed === "programs" ? <ProgramFeed /> : <CompaniesFeed relationType={displayed} />}
        </div>
      </div>
    </>
  );
}
