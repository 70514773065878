import { Helmet } from "react-helmet-async";
import { useParams } from "react-router";
import Facebook from "../components/Icons/Facebook";
import Instagram from "../components/Icons/Instagram";
import Linkedin from "../components/Icons/Linkedin";
import Twitter from "../components/Icons/Twitter";
import Loading from "../components/Loading";
import MarkDown from "../components/MarkDown";
import usePageReset from "../hooks/usePageReset";
import usePersonProfile from "../hooks/usePersonProfile";

export default function PersonProfile() {
  const { personID } = useParams();
  const [person, isLoading] = usePersonProfile(personID as string);
  const pageReset = usePageReset();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="keywords" content="profile person cultiv" />
        <meta
          name="description"
          content={`A personal profile page of ${
            person
              ? person.name
              : "someone who is a principal, team member or belongs to the network of Cultiv LLC "
          }`}
        />
        <meta name="robots" content="index, follow" />
        <title>Cultiv | {`${person ? person.name : "Profile"}`}</title>
      </Helmet>
      {isLoading ? (
        <div className="flex justify-center items-center h-screen">
          <Loading />
        </div>
      ) : (
        <div className="w-full flex flex-col gap-5 px-8 mt-8 relative">
          <div className="flex justify-between pb-3 border-b-2 border-gray-400">
            <div className="flex flex-col">
              <h1 className="font-bold text-xl md:text-3xl">{person?.name}</h1>
              <div className="flex-col">
                {person.organizations?.map((org, index) => {
                  return (
                    <p key={index} className="text-sm md:text-xl text-gray-500">
                      {org.job_title}
                    </p>
                  );
                })}
              </div>
            </div>
            <div className="flex justify-start gap-3 items-start mt-4">
              {person?.contacts?.linkedin && (
                <a href={person.contacts.linkedin} target="_blank">
                  <Linkedin color="black" hoverColor="cultiv-yellow" />
                </a>
              )}
              {person?.contacts?.facebook && (
                <a href={person.contacts.facebook} target="_blank">
                  <Facebook color="black" hoverColor="cultiv-yellow" />
                </a>
              )}
              {person?.contacts?.twitter && (
                <a href={person.contacts.twitter} target="_blank">
                  <Twitter color="black" hoverColor="cultiv-yellow" />
                </a>
              )}
              {person?.contacts?.instagram && (
                <a href={person.contacts.instagram} target="_blank">
                  <Instagram color="black" hoverColor="cultiv-yellow" />
                </a>
              )}
            </div>
          </div>

          <div className="flex flex-col lg:flex-row gap-2 lg:gap-12 items-center lg:items-start">
            <img
              src={person?.profile_picture ? person?.profile_picture : "//images/2815428.png"}
              alt={person?.name}
              height={440}
              width={440}
              className="object-contain"
            />

            <div className="flex-1 lg:w-1/2">
              <MarkDown articleBody={person?.md_bio || ""} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
