import { Person } from "../../schema";

export const panelI = `
## **Impact investment in Africa and the MENA Region**
###### The Ambassador of the Kingdom of Netherlands residence in Cairo. 
###### 14 Sep 2022.

Impact investment aims to generate profit while also creating an impact, it is therefore inevitable that this emerging industry is facing many challenges while sourcing viable investments that are able to meet both financial and social, environmental objectives.
As a launch event for the Cultivators Community, we organized a panel discussion on Impact Investment which was held on Wednesday, 14th of September 2022 at The Ambassador of the Kingdom of Netherlands'residence in Cairo. This was a very private event to which top niche attendees were invited so indeed it affected the marketing strategies and the design of the event.
The Event covered the challenges of the Impact investment in Africa and the MENA region such as diverse socio-economic landscapes, and limited access. These challenges can hinder the identification and assessment of investment opportunities and impact measurement, making it difficult to allocate capital effectively.`;

export const panelists: Person[] = [
  {
    id: 1,
    idea_name: "ayman-ismail",
    name: "Ayman Ismail",
    job_title: "Abdul Latif Jameel Endowed Chair of Entrepreneurship",
    profile_picture: "/images/cultivators/ezgif-2-ba748b5dd7.jpg",
  },
  {
    id: 2,
    idea_name: "ayman-shehata",
    name: "Ayman Shehata",
    job_title: "Managing Partner, ElRehlaModerator",
    profile_picture: "/images/cultivators/SSM-Cairo-Ayman-Shehata.jpg",
  },
  {
    id: 3,
    idea_name: "dalia-ibrahim",
    name: "Dalia Ibrahim",
    job_title: "CEO  of Nahdet Misr Publishing House and Founder of EdVentures",
    profile_picture: "/images/cultivators/1516463533519.jpg",
  },
  {
    id: 4,
    idea_name: "nivanne Mortagy",
    name: "Nivanne Mortagy",
    job_title: "Lead for North Africa, FIG, Upstream, IFC",
    profile_picture: "/images/cultivators/nivanne-mortagy.jpg",
  },
  {
    id: 5,
    idea_name: "Amr Abodraiaa",
    name: "Amr Abodraiaa",
    job_title: "CEO and Co-Founder of Rology",
    profile_picture: "/images/cultivators/amr-abodraiaa-headshot.jpg",
  },
  {
    id: 6,
    name: "Ibrahim Sagna",
    idea_name: "ibrahim",
    job_title: "Global Head, Advisory & Capital Markets of Afreximbank",
    profile_picture:
      "/images/cultivators/Ibrahim_Sagna_f037da1f695e33a8bfdcfd5b9d432705_6e8e8b403c.jpg",
  },
];
