import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Toaster } from "react-hot-toast";
import { useNavigate, useParams } from "react-router";
import { cultivatorsEventTabs } from "../assets/pageTabs";
import AboutCultivators from "../components/AboutCultivators";
import Gallery from "../components/Gallery";
import GridController from "../components/GridController";
import PrevEvent from "../components/PrevEvent";
import UpcomingEventsSection from "../components/UpcomingEventsSection";
import usePageReset from "../hooks/usePageReset";
import usePathname from "../hooks/usePathname";

const CultivatorsEvent = () => {
  const { eventTab } = useParams();
  const { page } = usePathname();
  const navigate = useNavigate();
  const [displayed, setDisplayed] = useState<string>(
    page === "lisbon" ? cultivatorsEventTabs[2].id : cultivatorsEventTabs[0].id
  );
  const setTab = (id: string) => {
    setDisplayed(id);
  };

  const pageReset = usePageReset();

  useEffect(() => {
    if (eventTab) {
      const index = cultivatorsEventTabs.findIndex((tab) => tab.id === eventTab);
      if (index >= 0) {
        setDisplayed(eventTab as string);
      } else {
        navigate(`/404`);
      }
    }
  }, [eventTab]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="keywords" content="event cultivators lisbon cultiv portugal Forum" />
        <meta
          name="description"
          content="Cultivators Forum is an event organized and funded by cultiv this year the event is taking place in lisbon "
        />
        <meta name="robots" content="index, follow" />
        <title>Cultivators Event</title>
      </Helmet>
      <div className={`w-full flex flex-col justify-center items-center gap-14 pt-8`}>
        <div className="pl-6 w-full flex flex-col gap-4">
          <img
            className="object-cover aspect-[16/2] pr-7"
            src={
              page === "/lisbon"
                ? "/images/cultivators/2023.jpg"
                : eventTab === "P-I"
                ? "/images/cultivators/2022.jpg"
                : eventTab === "P-II"
                ? "/images/cultivators/2023.jpg"
                : eventTab === "cultivators"
                ? "/images/cultivators/Panel2-100.jpg"
                : "/images/cultivators/Gallery.jpg"
            }
            alt="Cultivators Cover"
          />

          <div className="w-full h-full pr-6">
            <GridController
              tabs={cultivatorsEventTabs}
              displayed={displayed}
              setDisplayed={setTab}
              page="cultivators-forum"
            />
          </div>
          {displayed === "cultivators" ? (
            <AboutCultivators />
          ) : displayed === "P-II" ? (
            <UpcomingEventsSection />
          ) : displayed === "P-I" ? (
            <PrevEvent />
          ) : (
            <Gallery />
          )}
          <Toaster />
        </div>
      </div>
    </>
  );
};

export default CultivatorsEvent;
