import { createBrowserRouter } from "react-router-dom";
import DefaultLayout from "./components/Layouts/DefaultLayout";
import About from "./pages/About";
import Article from "./pages/Article";
import Careers from "./pages/Careers";
import Collective from "./pages/Collective";
import CompanyProfile from "./pages/CompanyProfile";
import Cult from "./pages/Cult";
import Cultivation from "./pages/Cultivation";
import CultivatorsEvent from "./pages/CultivatorsEvent";
import Culture from "./pages/Culture";
import ErrorPage from "./pages/ErrorPage";
import Home from "./pages/Home";
import PersonProfile from "./pages/PersonProfile";
import ProgramPage from "./pages/ProgramPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <DefaultLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/careers",
        element: <Careers />,
      },
      {
        path: "/lisbon",
        element: <CultivatorsEvent />,
      },
      {
        path: "/join",
        element: <Cult />,
      },
    ],
  },
  {
    path: "/cult",
    element: <DefaultLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/cult",
        element: <Cult />,
      },
      {
        path: "/cult/:department",
        element: <Cult />,
      },
    ],
  },
  {
    path: "/collective",
    element: <DefaultLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/collective",
        element: <Collective />,
      },
      {
        path: "/collective/:companyType",
        element: <Collective />,
      },
    ],
  },
  {
    path: "/cultivation",
    element: <DefaultLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/cultivation",
        element: <Cultivation />,
      },
      {
        path: "/cultivation/:cultivationTab",
        element: <Cultivation />,
      },
      {
        path: "/cultivation/program/:programID",
        element: <ProgramPage />,
      },
    ],
  },
  {
    path: "/culture",
    element: <DefaultLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/culture",
        element: <Culture />,
      },
      {
        path: "/culture/:cultureTab",
        element: <Culture />,
      },
      {
        path: "/culture/article/:articleID",
        element: <Article />,
      },
      {
        path: "/culture/event/:articleID",
        element: <Article />,
      },
      {
        path: "/culture/news/:articleID",
        element: <Article />,
      },
    ],
  },
  {
    path: "/cultivators-forum",
    element: <DefaultLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/cultivators-forum",
        element: <CultivatorsEvent />,
      },

      {
        path: "/cultivators-forum/:eventTab",
        element: <CultivatorsEvent />,
      },
    ],
  },
  {
    path: "/organizations/:orgSlug",
    element: <DefaultLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/organizations/:orgSlug",
        element: <CompanyProfile />,
      },
    ],
  },
  {
    path: "/profile/:personID",
    element: <DefaultLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/profile/:personID",
        element: <PersonProfile />,
      },
    ],
  },
  {
    path: "/404",
    element: <ErrorPage />,
  },
]);

export default router;
