import { panelI, panelists } from "../assets/cultivators/panelI";
import MarkDown from "./MarkDown";
import PersonCard from "./PersonCard";

const PrevEvent = () => {
  return (
    <div className="flex flex-col w-full gap-16">
      <MarkDown
        proseHeaders="prose-headings:ml-0 prose-headings:mb-0 prose-h6:text-gray-600"
        proseLead="prose-lead:leading-none"
        proseList="prose-ul:list-disc"
        proseParagraph="prose-p:mb-0"
        prosePadding="pr-8"
        articleBody={panelI}
      ></MarkDown>
      <div className="sm:text-5xl text-xl font-bold self-center">
        <h1>Meet The Panelists</h1>
      </div>
      <div
        className={`grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-y-8 w-full place-items-center gap-x-8 pr-6 sm:pr-12`}
      >
        {panelists.map((panelist) => {
          return <PersonCard key={panelist.id} person={panelist} panelist={true} />;
        })}
      </div>
    </div>
  );
};

export default PrevEvent;
