const AboutPageIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 1450 321">
      <path fill="#000" d="M0 0h1450v321H0z" />
      <path
        fill="#2424C9"
        d="M1397 12h43v43h-43zM1241 161h113v46h-113zM1397 98h43v43h-43zM1397 184h43v43h-43zM1397 270h43v43h-43z"
      />
      <path fill="#EC494A" d="M1354 227h43v43h-43z" />
      <path
        fill="#E55CB4"
        d="M1306 292.678 1323.678 275l17.677 17.678-17.677 17.678z"
      />
      <path
        fill="#EC494A"
        d="M1241 207h55v49h-55zM1354 313h43v8h-43zM1354 141h43v43h-43zM1001 69h43v43h-43zM1354 55h43v43h-43zM1354 0h43v12h-43z"
      />
      <circle cx={1194} cy={114} r={47} fill="#2424C9" />
      <circle cx={1323} cy={34} r={14} fill="#FFC501" />
      <mask
        id="a"
        width={79}
        height={43}
        x={1044}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: "alpha",
        }}
      >
        <path fill="#EC494A" d="M1044 0h79v43h-79z" />
      </mask>
      <g mask="url(#a)">
        <circle cx={1082} cy={15} r={26} fill="#E55CB4" />
      </g>
      <path
        fill="#00DEB5"
        d="M1241 67h113v13l-.5 3-.5 2-.5 2-.5 2-1 2.5-1.5 3.5-1.5 4-2 4.5-1.5 3-2 4-2 3.5-3 4-3.5 4.5-2.5 3-3.5 3.5-3 3-3.5 3-3 2.5-3.5 2.5-3 2-4 2.5-4 2.5-4.5 2.5-3 1.5-3.5 1.5-4 1.5-4.5 1.5-7 2-5.5 1.5-6.5 1.5-5 .5H1241V67Z"
      />
      <path fill="#2424C9" d="M1316 0h-75v67l75-67Z" />
      <path fill="#EC494A" d="M1241 67h-94.5V0h28l66.5 67Z" />
      <path fill="#00DEB5" d="M1055 67h91.5l-.5 94-91-94Z" />
      <path fill="#E55CB4" d="M1147 321v-25l24 25h-24Z" />
      <path
        fill="#E55CB4"
        fillRule="evenodd"
        d="M996.993 321c.005-.166.007-.333.007-.5 0-9.665-7.835-17.5-17.5-17.5s-17.5 7.835-17.5 17.5c0 .167.002.334.007.5h34.986Z"
        clipRule="evenodd"
      />
      <path
        fill="#00DEB5"
        fillRule="evenodd"
        d="M1284.43 321a46.867 46.867 0 0 0 3.57-18c0-25.957-21.04-47-47-47s-47 21.043-47 47c0 6.376 1.27 12.456 3.57 18h86.86Z"
        clipRule="evenodd"
      />
      <path
        fill="#00DEB5"
        fillRule="evenodd"
        d="M1223.71 321c.19-2.307.29-4.642.29-7 0-17.15-5.26-33.07-14.27-46.233L1208.5 269l-1.5 1.5-1.5 1.5-1.5 2h-.5c9.1 11.934 14.5 26.836 14.5 43 0 1.342-.04 2.676-.11 4h5.82Zm-163.42 0a85.273 85.273 0 0 1-.29-7c0-45.287 36.71-82 82-82 22.63 0 43.13 9.171 57.97 24h-16.47l.3.267C1173.07 249.752 1160.47 246 1147 246c-39.21 0-71 31.788-71 71 0 1.342.04 2.676.11 4h-15.82Z"
        clipRule="evenodd"
      />
      <path fill="#FFC501" d="M1146 161h95v95h-95z" />
      <path
        fill="#fff"
        d="M202.346 208.536c-7.936 0-14.848-1.92-20.736-5.76s-10.496-9.216-13.824-16.128c-3.243-6.997-4.864-15.104-4.864-24.32 0-9.387 1.664-17.536 4.992-24.448 3.413-6.912 8.149-12.245 14.208-16 6.059-3.84 13.141-5.76 21.248-5.76 6.485 0 12.416 1.323 17.792 3.968 5.461 2.56 9.856 6.315 13.184 11.264 3.413 4.949 5.248 10.923 5.504 17.92h-25.856c-.341-3.584-1.451-6.613-3.328-9.088-1.792-2.475-4.352-3.712-7.68-3.712-2.901 0-5.419.981-7.552 2.944s-3.797 4.864-4.992 8.704c-1.109 3.755-1.664 8.491-1.664 14.208 0 5.973.597 10.88 1.792 14.72 1.195 3.755 2.816 6.528 4.864 8.32 2.048 1.792 4.352 2.688 6.912 2.688 2.304 0 4.309-.512 6.016-1.536 1.707-1.109 3.072-2.645 4.096-4.608 1.109-2.048 1.792-4.437 2.048-7.168h25.856c-.683 6.827-2.56 12.8-5.632 17.92-3.072 5.035-7.296 8.96-12.672 11.776-5.376 2.731-11.947 4.096-19.712 4.096Zm78.514 0c-7.936 0-14.72-1.408-20.352-4.224-5.547-2.816-9.813-6.784-12.8-11.904-2.901-5.205-4.352-11.307-4.352-18.304v-56.448h25.216v57.088c0 2.731.469 5.12 1.408 7.168 1.024 1.963 2.475 3.499 4.352 4.608 1.877 1.024 4.053 1.536 6.528 1.536 2.645 0 4.864-.512 6.656-1.536 1.792-1.109 3.2-2.645 4.224-4.608 1.024-2.048 1.536-4.437 1.536-7.168v-57.088h25.216v56.448c0 6.997-1.493 13.099-4.48 18.304-2.901 5.12-7.168 9.088-12.8 11.904-5.547 2.816-12.331 4.224-20.352 4.224ZM327.63 207v-89.344h25.216v68.864h41.728V207H327.63Zm99.506 0v-68.992h-24.704v-20.352h74.624v20.352h-24.704V207h-25.216Zm79.282 0v-89.344h25.216V207h-25.216Zm-19.072-89.344h63.36v20.352h-63.36v-20.352Zm0 68.864h63.36V207h-63.36v-20.48ZM580.324 207l-20.352-89.344h25.216l10.24 47.744 3.328 22.784 3.2-22.784 10.368-47.744h25.088L616.804 207h-36.48Z"
      />
    </svg>
  );
};

export default AboutPageIcon;
