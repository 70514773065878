import { useNavigate } from "react-router";
import { Tab } from "../schema";

export interface Props {
  displayed: string;
  setDisplayed: (id: string) => void;
  tabs: Tab[];
  page: string;
  resetPage?: (pageNumber: number) => void;
}

export default function GridController({ displayed, setDisplayed, tabs, page, resetPage }: Props) {
  const navigate = useNavigate();
  return (
    <div className="border-b-2 border-gray-400 flex gap-6 md:pb-4 p-0 w-full items-center justify-start">
      {tabs.map((tab, index) => {
        return (
          <button
            key={index}
            className={`2xl:text-3xl lg:text-2xl md:text-xl text-sm text-gray-500 transition duration-500 hover:text-gray-900 ${
              displayed === tab.id && " !text-gray-900"
            } `}
            onClick={() => {
              setDisplayed(tab.id);
              resetPage && resetPage(1);
              navigate(`/${page}/${tab.id}`);
            }}
          >
            {tab.name}
          </button>
        );
      })}
    </div>
  );
}
