import { ColorRing } from "react-loader-spinner";

const Loading = () => {
  return (
    <>
      <ColorRing
        ariaLabel="color-ring-loading"
        wrapperStyle={{}}
        wrapperClass="color-ring-wrapper"
        colors={["#EC494A", "#FFC501", "#E55CB4", "#00DEB5", "#2424C9"]}
        width="96"
        visible={true}
      />
    </>
  );
};

export default Loading;
