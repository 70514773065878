import { Helmet } from "react-helmet-async";
import { useParams } from "react-router";
import Image from "../components/Image";
import Loading from "../components/Loading";
import MarkDown from "../components/MarkDown";
import useArticle from "../hooks/useArticle";
import usePageReset from "../hooks/usePageReset";

export default function Article() {
  const { articleID } = useParams();
  const [post, isLoading] = useArticle(articleID as string);
  const pageReset = usePageReset();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="keywords" content={`${post?.title} blog post article cultiv`} />
        <meta
          name="description"
          content={`This is an article about ${post?.title} written by ${post?.post_meta?.author}`}
        />
        <meta name="robots" content="index, follow" />
        <title>Cultiv | {`${post ? post.title : "Article"}`}</title>
      </Helmet>

      {isLoading ? (
        <div className="flex justify-center items-center h-0 min-h-screen">
          <Loading />
        </div>
      ) : (
        <div className="w-full flex flex-col justify-center items-center gap-5 px-8 mt-8">
          <div className="flex flex-col gap-3 w-full justify-center items-center pb-8 border-b-2 border-gray-400 text-center">
            <h1 className="text-4xl font-bold">{post?.title}</h1>
            <p className="text-xl text-gray-500">
              {post?.created_at}
              {post.post_meta.author && `, By ${post?.post_meta?.author}`}
            </p>
          </div>
          <Image
            src={post?.post_cover}
            width={1000}
            height={500}
            className=" object-cover"
            alt={post?.title}
          />
          <MarkDown articleBody={post?.md_text} prosePadding="sm:px-24" />
        </div>
      )}
    </>
  );
}
