import { panelII } from "../assets/cultivators/panelII";
import MarkDown from "./MarkDown";

const UpcomingEventsSection = () => {
  return (
    <div className={`flex flex-col gap-6 `}>
      <MarkDown
        proseHeaders="prose-headings:ml-0"
        proseLead="prose-lead:leading-none"
        proseList="prose-ul:list-disc"
        proseParagraph="prose-p:mb-0"
        prosePadding="pr-8"
        articleBody={panelII[0]}
      ></MarkDown>
      <MarkDown
        proseHeaders="prose-headings:ml-0"
        proseLead="prose-lead:leading-none"
        proseList="prose-ul:list-disc"
        proseParagraph="prose-p:mb-0"
        prosePadding="pr-8"
        articleBody={panelII[1]}
      ></MarkDown>
    </div>
  );
};

export default UpcomingEventsSection;
