import { Helmet } from "react-helmet-async";
import usePageReset from "../hooks/usePageReset";

export default function Careers() {
  const pageReset = usePageReset();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="keywords" content="job opportunity vacancy cultiv" />
        <meta name="description" content="Join our creative team" />
        <meta name="robots" content="index, follow" />
        <title>Cultiv | Careers</title>
      </Helmet>
      <div className="flex flex-col md:flex-row items-center md:justify-between py-8 px-4 sm:px-8">
        <div className="flex flex-col gap-16">
          <div className="flex flex-col gap-8">
            <h1 className="font-bold text-2xl md:text-4xl">Become one of us</h1>
            <p className="md:text-xl leading-10 text-justify">
              Cultiv has core values that impact its culture and strategy and build a sense of
              commitment. The company empowers its employees by encouraging innovation and
              emphasizing that small changes today lead to massive changes tomorrow. Our work
              relations are based on respect and integrity and embrace diversity. Do you want to be
              part of our team, leading the change and leaving an impact everywhere?
            </p>
          </div>
          <div className="flex flex-col">
            <h2 className="text-xl">Email us now at: </h2>
            <a
              className="text-gray-900 font-bold text-2xl  hover:underline self-center"
              href="mailto:careers@cultiv.llc"
            >
              careers@cultiv.llc
            </a>
          </div>
        </div>
        <img
          src="/images/careerIcon.png"
          className="object-contain w-1/2"
          alt="Join the cultivators"
          height={375}
          width={500}
        />
      </div>
    </>
  );
}
