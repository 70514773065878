import { Transition } from "@headlessui/react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useTextTruncation from "../hooks/useTextTruncation";
import Image from "./Image";

export interface Props {
  thumbImg: string;
  title: string;
  date: string;
  content: string;
  author?: string;
  slug: string;
  type: string;
}

export default function Card({ content, date, slug, thumbImg, title, type, author }: Props) {
  const [isInside, setIsInside] = useState<boolean>(false);
  const truncatedText = useTextTruncation(content, 150);
  const navigate = useNavigate();

  return (
    <div
      className="border-gray-100 w-full aspect-square relative flex flex-col items-start justify-start"
      onMouseEnter={() => {
        setIsInside(true);
      }}
      onMouseLeave={() => {
        setIsInside(false);
      }}
    >
      <div className=" absolute top-0 left-0 w-full -z-9 p-3 font-black bg-gradient-to-b from-gray-900">
        <h1 className="text-[#faf8f4] font-bold 3xl:text-3xl xl:text-xl lg:text-base md:text-2xl text-xl h-full">
          {title}
        </h1>
      </div>
      <div className=" absolute bottom-0 right-0 p-1 -z-9 font-black bg-gray-900 flex bg-opacity-60 rounded-tl-md justify-end items-end">
        <h1 className="text-[#faf8f4] font-bold 3xl:text-lg xl:text-base text-sm h-full">{type}</h1>
      </div>
      <div className="absolute top-0 left-0 w-full h-full -z-10 ">
        <Image src={thumbImg} alt={title} />
      </div>
      <Transition
        show={isInside}
        enter="transition-opacity ease-in-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-in-out duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={"w-full h-full z-20"}
      >
        <div
          onClick={() => {
            navigate(`/culture/${type}/${slug}`);
          }}
          className="w-full h-full max-h-full p-3 bg-cultiv-red flex flex-col gap-3 cursor-pointer"
        >
          <div className="flex flex-col h-full">
            <div className="flex flex-col gap-2">
              <h1 className="3xl:text-3xl xl:text-xl lg:text-base md:text-2xl text-xl font-semibold 2xl:font-bold text-gray-950">
                {title}
              </h1>
              <div className="flex flex-col text-xs md:text-sm lg:text-xs xl:text-xs 2xl:text-base text-[#302D2D] tracking-tight">
                <h3>{author}</h3>
                <h3>Published on {date}</h3>
              </div>
            </div>
            <div className="flex justify-center items-center flex-1">
              <p className="3xl:text-2xl lg:text-base md:text-lg text-base ">{truncatedText}</p>
            </div>
          </div>
          <Link
            to={`/culture/${type}/${slug}`}
            className="px-3 lg:py-1 xl:py-[1px] 2xl:py-1 ease-in-out duration-500 flex justify-center items-center 2xl:w-[180px] xl:w-[120px] lg:w-[160px] border-[3px] border-gray-950 hover:bg-gray-950 hover:text-gray-50 border-solid 2xl:text-xl"
          >
            Read More
          </Link>
        </div>
      </Transition>
    </div>
  );
}
