import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { FeedItemCard } from "../schema";

const useCultureTabs = (tabId: string): [FeedItemCard[], boolean] => {
  const [tabItems, setTabItems] = useState<FeedItemCard[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    let cancelRequest = false;
    const fetchTabItems = async () => {
      try {
        const response = await fetch(
          import.meta.env.VITE_GET_FEED_ITEMS + `?is_short=1&type=${tabId}`,
          {
            method: "GET",
          }
        );
        if (!response.ok) {
          throw new Error(
            `error code: ${response.status}, Tab not found or media type doesn't exist`
          );
        }
        if (!cancelRequest) {
          const { data } = await response.json();
          setTabItems(data);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        navigate(`/404`);
      }
    };
    fetchTabItems();
    return () => {
      cancelRequest = true;
    };
  }, [tabId, setTabItems]);
  return [tabItems, isLoading];
};

export default useCultureTabs;
