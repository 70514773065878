import { contacts, footerLinks } from "../assets/footer";
import ContactDetails from "./ContactDetails";
import FooterLink from "./FooterLink";
import Facebook from "./Icons/Facebook";
import Instagram from "./Icons/Instagram";
import Linkedin from "./Icons/Linkedin";
import Twitter from "./Icons/Twitter";

export default function Footer() {
  return (
    <footer className="bg-black mt-4">
      <div className="flex flex-col gap-12 pl-4 lg:pl-6 pr-10 py-8 text-gray-50">
        <div>
          <img
            src="/images/CULTIV.svg"
            alt="Cultiv Logo"
            className="aspect-square w-1/6 md:w-max"
            width={130}
            height={130}
          />
        </div>
        <div className="flex gap-6 md:gap-1 justify-between">
          <div className="flex flex-col gap-6">
            <h2 className=" text-lg sm:text-xl md:text-2xl xl:text-3xl font-bold uppercase">
              Quick links
            </h2>
            <div className="flex flex-col gap-6 font-normal">
              {footerLinks.map((link, index) => {
                return (
                  <FooterLink
                    key={index}
                    title={link.title}
                    pathname={link.pathname}
                  />
                );
              })}
            </div>
          </div>
          <div className="flex flex-col items-center justify-center gap-6">
            <h2 className="text-lg sm:text-xl md:text-2xl xl:text-3xl font-bold uppercase">
              contact us
            </h2>
            {contacts.map(({ contact }, index) => {
              return <ContactDetails key={index} contact={contact} />;
            })}
          </div>
          <div className="flex flex-col gap-6">
            <h2 className="text-lg sm:text-xl md:text-2xl xl:text-3xl font-bold uppercase">
              socials
            </h2>
            <div className="flex flex-col gap-8 w-full">
              <div className="flex justify-between">
                <a
                  href="https://www.linkedin.com/company/cultivllc"
                  target="_blank"
                  aria-label="Go to cultiv's Linkedin page"
                >
                  <Linkedin color="white" hoverColor="cultiv-yellow" />
                </a>
                <a
                  href="https://www.facebook.com/cultivllc"
                  target="_blank"
                  aria-label="Go to cultiv's Facebook page"
                >
                  <Facebook color="white" hoverColor="cultiv-yellow" />
                </a>
              </div>
              <div className="flex justify-between">
                <a
                  href="https://twitter.com/CultivLLC"
                  target="_blank"
                  aria-label="Go to cultiv's X account"
                >
                  <Twitter color="white" hoverColor="cultiv-yellow" />
                </a>
                <a
                  href="https://www.instagram.com/cultiv.llc/"
                  target="_blank"
                  aria-label="Go to cultiv's Instagram account"
                >
                  <Instagram color="white" hoverColor="cultiv-yellow" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
