import { CollectiveCompanies, Tab } from "../schema";
import { Departments } from "../schema";

export const collectiveTabs: Tab[] = [
  { name: "Members", id: CollectiveCompanies.members },
  { name: "Partners", id: CollectiveCompanies.partners },
];

export const cultTabs: Tab[] = [
  { name: "Principals", id: Departments.principals },
  { name: "Team", id: Departments.team },
  { name: "Cultivators", id: Departments.cultivators },
  { name: "Innovators", id: Departments.innovators },
];

export const cultivationTabs: Tab[] = [
  { name: "Programs", id: "programs" },
  { name: "Clients", id: "client" },
  // { name: "Startups", id: "alumnus" },
];

export const cultureTabs: Tab[] = [
  { name: "Articles", id: "article" },
  { name: "Events", id: "event" },
  { name: "News", id: "news" },
];

export const cultivatorsEventTabs: Tab[] = [
  { name: "Overview", id: "cultivators" },
  { name: "Panel #1", id: "P-I" },
  { name: "Panel #2", id: "P-II" },
  { name: "Gallery", id: "gallery" },
];
