import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useDispatch } from "react-redux";
import { nextPage } from "../redux/features/pagePaginationSlice";

export interface Props {
  pageId: string;
}

const ShowMoreBtn = ({ pageId }: Props) => {
  const dispatch = useDispatch();

  const next = () => {
    dispatch(nextPage(pageId));
  };

  return (
    <button
      className="flex justify-center border-4 border-cultiv-yellow hover:bg-cultiv-yellow ease-in-out duration-500 items-center  p-2 mt-4"
      onClick={next}
      aria-label="Show more"
    >
      <ChevronDownIcon color="black" height={40} width={40} />
    </button>
  );
};

export default ShowMoreBtn;
