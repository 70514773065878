import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface PageDetails {
  id: string;
  currentPage: number;
  maxPage: number;
  buttonShouldRender: boolean;
}

export interface PagePaginationSlice {
  pages: PageDetails[];
}

export interface MaxPagePayload {
  id: string;
  maxPage: number;
}

const initialState: PagePaginationSlice = {
  pages: [{ id: "home", currentPage: 1, maxPage: 0, buttonShouldRender: false }],
};

export const PagePaginationSlice = createSlice({
  name: "pagePagination",
  initialState,
  reducers: {
    setMaxPage: (state, action: PayloadAction<MaxPagePayload>) => {
      const { id, maxPage } = action.payload;
      const pageIndex = getPageIndex(state, id);
      if (pageIndex > -1) {
        state.pages[pageIndex].maxPage = maxPage;
        if (maxPage > 1) {
          state.pages[pageIndex].buttonShouldRender = true;
        }
      }
    },
    nextPage: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      const pageIndex = getPageIndex(state, payload);
      if (pageIndex > -1) {
        const newPage = state.pages[pageIndex].currentPage + 1;
        if (newPage <= state.pages[pageIndex].maxPage) {
          state.pages[pageIndex].currentPage = newPage;
          if (newPage === state.pages[pageIndex].maxPage) {
            state.pages[pageIndex].buttonShouldRender = false;
          }
        }
      }
    },
    reset: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      const pageIndex = getPageIndex(state, payload);
      if (pageIndex > -1) {
        state.pages[pageIndex].currentPage = 1;
      }
    },
  },
});

export const { setMaxPage, nextPage, reset } = PagePaginationSlice.actions;
export default PagePaginationSlice.reducer;

export const getPageIndex = (state: PagePaginationSlice, pageId: string) => {
  const pageIndex = state.pages.findIndex((page) => pageId === page.id);
  return pageIndex;
};

export const getCurrentPage = (state: PagePaginationSlice, pageId: string) => {
  const pageIndex = getPageIndex(state, pageId);
  return state.pages[pageIndex].currentPage;
};

export const getButtonStatus = (state: PagePaginationSlice, pageId: string) => {
  const pageIndex = getPageIndex(state, pageId);
  return state.pages[pageIndex].buttonShouldRender;
};
