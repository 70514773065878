import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import navTabs from "../assets/navTabs";
import { RootState } from "../redux/configureStore";
import { reverse } from "../redux/features/mobileDrawerSlice";
import NavMenu from "./NavMenu";

export default function Navbar() {
  const isOpen = useSelector((state: RootState) => state.drawerController.drawerShouldOpen);
  const dispatch = useDispatch();
  const reverseDrawer = () => {
    dispatch(reverse());
  };

  return (
    <>
      <nav className="relative bg-black w-full flex justify-between py-6 z-10 px-8">
        <div className="flex items-center justify-between flex-1">
          <Link to={`/`}>
            <img
              src="/images/CULTIV.svg"
              className="aspect-square w-2/3"
              alt="Cultiv square logo"
              width={130}
              height={130}
            />
          </Link>
          <div className="flex md:hidden px-6">
            <button
              onClick={reverseDrawer}
              type="button"
              className="text-white  hover:text-white  focus:outline-none focus:text-white"
              aria-label="toggle menu"
            >
              {isOpen ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                  mx-auto="true"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4 8h16M4 16h16" />
                </svg>
              )}
            </button>
          </div>
        </div>

        <div
          className={`${
            isOpen ? " opacity-100" : "opacity-0 translate-x-full"
          } absolute top-full inset-x-0 z-20 w-screen px-6 py-1 transition-all duration-300 ease-in-out bg-black md:mt-0 md:p-0 md:top-0 md:relative md:bg-transparent md:w-auto md:opacity-100 md:translate-x-0 md:flex md:items-center`}
        >
          <div className="flex flex-col sm:flex-row justify-between gap-4 lg:gap-16  text-gray-200 ">
            {navTabs.map((tab) => {
              return <NavMenu key={tab.id} tab={tab} />;
            })}
          </div>
        </div>
      </nav>
    </>
  );
}
