import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-responsive-modal";
import useCompanyProfile from "../hooks/useCompanyProfile";
import useTextTruncation from "../hooks/useTextTruncation";
import { RootState } from "../redux/configureStore";
import { closeModal } from "../redux/features/memberModalSlice";
import Loading from "./Loading";
import MarkDown from "./MarkDown";
import WebsiteLinkButton from "./WebsiteLinkButton";

export default function MemberPopup() {
  const dispatch = useDispatch();
  const { open, name } = useSelector((state: RootState) => state.memberModal);
  const close = () => {
    dispatch(closeModal());
  };
  const [memberData, isLoading] = useCompanyProfile(name);
  const truncatedText = useTextTruncation(memberData?.description_summary || "", 150);
  return (
    <Modal
      open={open}
      onClose={close}
      classNames={{
        root: "overlay modal-root",
        modalContainer: "member-container",
        modal: "member-modal",
        closeButton: "member-close",
      }}
      center
    >
      {isLoading ? (
        <div className="flex justify-center items-center w-full h-full p-2 sm:p-8">
          <Loading />
        </div>
      ) : (
        <div className="flex justify-between items-center w-full h-full">
          <img
            src={memberData?.profile_picture}
            alt={memberData?.name}
            className="aspect-square object-contain w-2/5 sm:w-1/2"
          />

          <div className="flex flex-col gap-1 p-1 sm:p-8 sm:gap-4 2xl:gap-8 w-3/5 sm:w-1/2">
            <h1 className="sm:text-lg md:text-2xl xl:text-4xl font-bold text-gray-900 ">
              {memberData?.name}
            </h1>
            <div className="flex-1">
              <MarkDown
                articleBody={isMobile ? truncatedText : memberData.description_summary}
                proseParagraph="prose-p:font-light prose-p:text-xs sm:prose-p:text-base xl:prose-p:text-xl 2xl:prose-p:text-2xl 3xl:prose-p:text-3xl"
                proseLead="sm:prose-lead:leading-10"
              />
            </div>
            {memberData?.link && (
              <WebsiteLinkButton link={memberData.link} text="Visit their website" />
            )}
          </div>
        </div>
      )}
    </Modal>
  );
}
