export interface Props {
  desc?: string;
  color?: string;
}

export default function Header({ color, desc }: Props) {
  return (
    <div
      id="header"
      className={`${color && color} relative flex md:gap-4 py-4 justify-start items-center px-8`}
    >
      <p className=" 2xl:text-2xl lg:text-xl md:text-base text-sm  text-white font-bold  text-start">
        {desc}
      </p>
    </div>
  );
}
