import React from "react";
import { Departments, Person } from "../schema";
import JoinSelector from "./JoinSelector";
import PersonCard from "./PersonCard";

export interface Props {
  members: Person[];
  displayed: string;
}

export default function PersonGrid({ members, displayed }: Props) {
  return (
    <div className="grid xl:grid-cols-4 lg:grid-cols-3 grid-cols-2 gap-8 w-full place-items-center">
      {members.length > 3 ? (
        members.map((member, index) => {
          return index === 2 ? (
            <React.Fragment key={member.id}>
              <PersonCard person={member} />
              {displayed !== Departments.principals && displayed !== Departments.innovators && (
                <JoinSelector displayed={displayed} />
              )}
            </React.Fragment>
          ) : (
            <PersonCard key={member.id} person={member} />
          );
        })
      ) : (
        <React.Fragment>
          {members.map((member) => {
            return <PersonCard key={member.id} person={member} />;
          })}
          {displayed !== Departments.principals && displayed !== Departments.innovators && (
            <JoinSelector displayed={displayed} />
          )}
        </React.Fragment>
      )}
    </div>
  );
}
