import usePathname from "../hooks/usePathname";
import { FeedItemCard } from "../schema";
import Card from "./Card";

export interface Props {
  cards: FeedItemCard[];
}

export default function CardsGrid(props: Props) {
  const { page } = usePathname();
  return (
    <div
      className={`grid ${
        page !== "culture" ? "xl:grid-cols-3" : "xl:grid-cols-4"
      } lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-3 w-full place-items-start`}
    >
      {props.cards.map(
        ({ post_summary, created_at, id, post_thumbnail, title, post_meta, title_slug, type }) => {
          return (
            <Card
              content={post_summary}
              date={created_at}
              key={id}
              thumbImg={post_thumbnail}
              title={title}
              author={post_meta.author}
              slug={title_slug}
              type={type}
            />
          );
        }
      )}
    </div>
  );
}
