import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import NotFoundIcon from "../components/Icons/NotFoundIcon";
import Navbar from "../components/Navbar";
import usePageReset from "../hooks/usePageReset";

export default function ErrorPage() {
  const navigate = useNavigate();
  const goHome = () => {
    navigate(`/`);
  };
  const pageReset = usePageReset();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Cultiv | Error</title>
      </Helmet>
      <main className="h-full min-h-screen w-screen flex flex-col justify-between relative overflow-x-hidden">
        <Navbar />
        <div className="flex-1 flex flex-col gap-16 w-full h-full relative px-4 sm:px-8 mb-12 sm:mb-8">
          <div className="sm:z-10 sm:w-5/12 lg:w-7/12 2xl:w-3/5 3xl:w-4/6 flex flex-col mt-8 sm:mt-24 gap-8 sm:gap-16">
            <h2 className="text-2xl sm:text-4xl lg:text-6xl font-bold">Whoops!</h2>
            <h1 className="pr-20 sm:pr-32 lg:pr-0 text-4xl sm:text-6xl lg:text-8xl font-bold text-cultiv-yellow text-center">
              404
            </h1>
            <h3 className="text-xl sm:text-3xl lg:text-4xl font-bold text-end ">Page Not Found</h3>
          </div>
          <p className="z-10 text-lg sm:text-2xl">
            The page you are looking for either expired or doesn't exist anymore
          </p>
          <Link
            to={`/`}
            className="py-2 px-4 min-w-fit self-center bg-gray-900 hover:bg-cultiv-yellow flex justify-center items-center text-xl font-semibold text-gray-50 hover:text-gray-950"
          >
            Go back to Home
          </Link>
          <div className="absolute max-w-fit w-3/4 h-1/3 sm:w-11/12 sm:h-1/2 md:w-1/2 lg:w-auto md:h-1/5 lg:h-auto top-5 sm:top-10 md:top-0 lg:top-2 xl:top-10 right-0 md:right-24 lg:right-6 xl:right-16 2xl:right-20">
            <NotFoundIcon />
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
}
