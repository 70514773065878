import { Navtab } from "../schema";

const navTabs: Navtab[] = [
  {
    id: "cult",
    hasSublinks: true,
    name: "Cult",
    sublinks: [
      { href: `/cult`, hash: `/principals`, label: "Principals" },
      { href: `/cult`, hash: `/team`, label: "Team" },
      { href: `/cult`, hash: `/cultivators`, label: "Cultivators" },
      { href: `/cult`, hash: `/innovators`, label: "Innovators" },
    ],
    to: "/cult",
  },
  {
    id: "collective",
    hasSublinks: true,
    name: "Collective",
    sublinks: [
      { href: `/collective`, hash: `/members`, label: `Members` },
      { href: `/collective`, hash: `/partners`, label: `Partners` },
    ],
    to: "/collective",
  },
  {
    id: "cultivation",
    hasSublinks: true,
    name: "Cultivation",
    sublinks: [
      { href: `/cultivation`, hash: `/programs`, label: `Programs` },
      { href: `/cultivation`, hash: `/client`, label: `Clients` },
    ],
    to: "/cultivation",
  },
  {
    id: "culture",
    hasSublinks: true,
    name: "Culture",
    sublinks: [
      { href: `/culture`, hash: `/article`, label: `Articles` },
      { href: `/culture`, hash: `/event`, label: `Events` },
      { href: `/`, hash: `cultivators-forum/cultivators`, label: `Cultivators Forum` },
      { href: `/culture`, hash: `/news`, label: `News` },
    ],
    to: "/culture",
  },
];

export default navTabs;
