export interface Props {
  color: string;
  hoverColor: string;
}

const Instagram = ({ color, hoverColor }: Props) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="36"
        viewBox="0 0 40 41"
        className={`text-${color} fill-current hover:text-${hoverColor}`}
      >
        <path
          fillRule="evenodd"
          d="M16.234 12.727a8.22 8.22 0 015.06-.86 8.287 8.287 0 014.67 2.388A8.513 8.513 0 0128.315 19a8.58 8.58 0 01-.846 5.14 8.397 8.397 0 01-3.65 3.661 8.22 8.22 0 01-5.071.796 8.289 8.289 0 01-4.569-2.371 8.51 8.51 0 01-2.335-4.64 8.581 8.581 0 01.784-5.15 8.402 8.402 0 013.606-3.708zm4.571 2.49a4.933 4.933 0 00-3.036.515 5.041 5.041 0 00-2.163 2.225 5.149 5.149 0 00-.47 3.09 5.107 5.107 0 001.4 2.784 4.974 4.974 0 002.741 1.423 4.932 4.932 0 003.043-.478 5.04 5.04 0 002.19-2.197 5.148 5.148 0 00.508-3.084 5.107 5.107 0 00-1.41-2.846 4.972 4.972 0 00-2.803-1.432z"
          clipRule="evenodd"
        ></path>
        <path d="M29.166 9.31c-.92 0-1.666.758-1.666 1.693s.746 1.693 1.666 1.693h.017c.92 0 1.667-.758 1.667-1.693s-.746-1.693-1.667-1.693h-.017z"></path>
        <path
          fillRule="evenodd"
          d="M1.667 11.85c0-5.61 4.477-10.157 10-10.157h16.666c5.523 0 10 4.547 10 10.157v16.927c0 5.61-4.477 10.157-10 10.157H11.667c-5.523 0-10-4.547-10-10.157V11.85zm10-6.772C7.984 5.078 5 8.11 5 11.85v16.927c0 3.74 2.985 6.771 6.666 6.771h16.667c3.682 0 6.667-3.031 6.667-6.77V11.85c0-3.74-2.985-6.772-6.667-6.772H11.667z"
          clipRule="evenodd"
        ></path>
      </svg>
    </div>
  );
};

export default Instagram;
