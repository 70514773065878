import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import Image from "../components/Image";
import MarkDown from "../components/MarkDown";
import usePageReset from "../hooks/usePageReset";
import useProgram from "../hooks/useProgram";

export default function ProgramPage() {
  const { programID } = useParams();
  const programDetails = useProgram(programID ? programID : "/404");
  const pageReset = usePageReset();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="keywords" content="cultiv program incubation incubator accelerator startup" />
        <meta name="description" content="A program implemented by Cultiv llc" />
        <meta name="robots" content="index, follow" />
        <title>Cultiv | {`${programDetails ? programDetails.title : "Program"}`}</title>
      </Helmet>
      <div className="w-full flex flex-col justify-center items-center gap-5 px-8 mt-8">
        <div className="flex flex-col w-full justify-center items-center pb-8 border-b-2 border-gray-400 text-center">
          <h1 className="text-4xl font-bold">{programDetails?.title}</h1>
        </div>
        <Image
          src={programDetails?.program_cover}
          width={1000}
          height={500}
          className="3xl:w-3/4"
          alt={programDetails?.title}
        />
        {programDetails?.description && (
          <MarkDown articleBody={programDetails.description} prosePadding="px-24" />
        )}
        {programDetails?.apply_link && (
          <div className="flex flex-col justify-center items-center w-full">
            <div className="rounded-full bg-cultiv-dark-blue w-2/12 h-10 md:p-0 flex justify-center items-center text-white shadow-sm  hover:border-blue-500 hover:border-2">
              <a href={programDetails.apply_link}>Apply now</a>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
