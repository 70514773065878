import { CultivContact, FooterRedirectLink } from "../schema";

export const contacts: CultivContact[] = [
  { contact: "CULTIV GmbH, 8 Untere Roostmatt, 6300 Zug, Switzerland" },
  { contact: "CULTIVnet Lda, 132 Rua Dom Afonso Henriques, Cortes 4950-854, Portugal" },
  { contact: "CULTIV Business LLC, 124 Othman Ibn Afan Street, Heliopolis, Cairo 11361, Egypt" },
  { contact: "CULTIV LLC, 16192 Coastal Highway, Lewes, DE 19958, USA" },
  { contact: "info@cultiv.llc" },
];

export const footerLinks: FooterRedirectLink[] = [
  { title: "About", pathname: "/about" },
  { title: "Careers", pathname: "/careers" },
];
