import { Link } from "react-router-dom";
import { FooterRedirectLink } from "../schema";

const FooterLink = ({ title, pathname }: FooterRedirectLink) => {
  return (
    <>
      <Link
        className="hover:text-cultiv-yellow text-sm sm:text-base md:text-xl xl:text-2xl"
        to={pathname}
      >
        {title}
      </Link>
    </>
  );
};

export default FooterLink;
