//type for nav drop down links

export type Navtab = {
  id: string;
  name: string;
  to: string;
  hasSublinks: boolean;
  sublinks?: SubLink[];
};

export interface SubLink {
  href: string;
  hash: string;
  label: string;
}

// Feed Item types

export interface PostMeta {
  author?: string;
  start_date?: string;
  end_date?: string;
  location?: string;
}
export interface Tag {
  id: number;
  tag: string;
}
export interface Post {
  id: number;
  service_id: number;
  title: string;
  title_slug: string;
  view_count: number;
  created_at: string;
  post_thumbnail: string;
  post_meta: PostMeta;
  rich_text: string;
  plain_text: string;
  md_text: string;
  post_cover: string;
  tags: Tag[];
}
export interface FeedItemCard {
  id: number;
  service_id: number;
  title: string;
  title_slug: string;
  post_summary: string;
  type: string;
  view_count: number;
  is_featured: number;
  created_at: string;
  post_thumbnail: string;
  post_meta: PostMeta;
  tags: Tag[];
}

// Company Card
export interface Company {
  id: number;
  name: string;
  name_slug: string;
  email: string;
  location: string;
  phone: string;
  description_summary: string;
  md_description: string;
  link: string;
  profile_picture: string;
}

// Program Types
export interface ProgramCard {
  id: number;
  title: string;
  title_slug: string;
  program_summary: string;
  program_thumbnail: string;
  cycles_count: number;
}

export interface Program {
  program_cover: string;
  title: string;
  description: string;
  apply_link: string;
}

// Person
export interface Contacts {
  linkedin?: string;
  facebook?: string;
  twitter?: string;
  instagram?: string;
}

export interface Organizations {
  job_title?: string;
  organization_name?: string;
}
export interface Person {
  id: number;
  idea_name: string;
  profile_picture?: string;
  job_title?: string;
  name: string;
  bio?: string;
  md_bio?: string;
  organizations?: [Organizations];
  contacts?: Contacts;
}

export interface Member {
  logo: string;
  slug: string;
  name: string;
}

export interface SliderItem {
  post_cover: string;
  title: string;
  title_slug: string;
  type: string;
  isDirectory?: boolean;
}

export type FooterRedirectLink = {
  title: string;
  pathname: string;
};

export type CultivContact = {
  contact: string;
};

export type Tab = {
  name: string;
  id: string;
};

export enum Departments {
  principals = "principals",
  team = "team",
  cultivators = "cultivators",
  innovators = "innovators",
}

export enum CollectiveCompanies {
  members = "members",
  partners = "partners",
}
